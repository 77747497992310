import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import { Content } from "components/Content";
import { TextBlock } from "components/TextBlock";
import { List } from "components/List";
import { Hero } from "components/Hero";
import { StaticImage } from "gatsby-plugin-image";

const NotFoundPage = (p) => {
  const title = "404 | Leeds Holi Festival";
  const description = "Page not found";

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: ``,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero
        image={{
          node: (
            <StaticImage
              src="../images/1.jpg"
              alt=""
              layout="fullWidth"
              objectFit="cover"
              objectPosition="center"
            />
          ),
          src: "/1.jpg",
        }}
      >
        <h1>Page not found</h1>
      </Hero>
      <Content>
        <List>
          <TextBlock>
            <p>Oops! This page doesn't exist.</p>
            <p>
              <Link to="/">Back to home</Link>
            </p>
          </TextBlock>
        </List>
      </Content>
    </React.Fragment>
  );
};

export default NotFoundPage;
